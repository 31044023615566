<template>
  <div class="import-container">
    <import-template :uploadUrl="roomUploadUrl" :downloadUrl="downloadUrl"  title="导入房间号">
      <div slot="remark">
        <br>1、若楼宇/项目中有一期、二期等组团，可将其作为分区填入。若楼幢中有单元或裙楼，可将其作为单元填入。否则分区和单元可为空
        <br>2、请将导入数据填入底部第二个工作表（sheet），即项目/园区/楼宇名称，名称需与系统中的项目名称一致，并不要移动工作表顺序

      </div>
    </import-template>
  </div>
</template>
<script>
import { ImportTemplate } from 'components/bussiness/index'
import { roomUploadUrl, downloadURL } from './api'
export default {
  components: {
    ImportTemplate
  },
  data () {
    return {
      roomUploadUrl,
      downloadUrl: `${downloadURL}?id=${this.$store.state.userInfo.id}`
    }
  },
  created () {
    this.$setBreadcrumb('房号导入')
  },
  methods: {
    previous () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="scss" scoped>
  .header {
    position: absolute;
    top: 20px;
    right: 20px;
    :not(:last-child) {
      margin-right: 8px;
    }
  }
</style>
