var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "import-container" },
    [
      _c(
        "import-template",
        {
          attrs: {
            uploadUrl: _vm.roomUploadUrl,
            downloadUrl: _vm.downloadUrl,
            title: "导入房间号",
          },
        },
        [
          _c("div", { attrs: { slot: "remark" }, slot: "remark" }, [
            _c("br"),
            _vm._v(
              "1、若楼宇/项目中有一期、二期等组团，可将其作为分区填入。若楼幢中有单元或裙楼，可将其作为单元填入。否则分区和单元可为空\n      "
            ),
            _c("br"),
            _vm._v(
              "2、请将导入数据填入底部第二个工作表（sheet），即项目/园区/楼宇名称，名称需与系统中的项目名称一致，并不要移动工作表顺序\n\n    "
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }